import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class CountyField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();
        const isAtlasPublicView = store.getters['shared/isAtlasPublicView'];

        return new RelationToOneField(
            name,
            label,
            (param) => {
                return new Promise((resolve) => {
                    const countyEndpoint = isAtlasPublicView ? 'guest-counties' : 'counties';
                    ApiService.get(countyEndpoint).then(({ data }) => {
                        if (param?.search) {
                            const result = data.filter(item => item.county.toLowerCase().startsWith(param.search));
                            resolve(result);
                        }
                        resolve(data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.county,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
